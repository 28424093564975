import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Qrcode.css';
import moment from 'moment';

function POC() {
  const BASE_URL = `https://api.khatatracker.com`;

  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const apiUrl = `${BASE_URL}/salerandom/${hash}`;
  
    const fetchInvoice = async (url) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log('response',data.saleInvoice
        )
        setInvoiceData(data.saleInvoice);
      
      } catch (err) {
        setError('Error fetching invoice data.');
      } finally {
        setLoading(false);
      }
    };
    if (hash) {
      fetchInvoice(apiUrl);
    } else {
      console.error('No hash found in the URL');
      setError('No hash found in the URL');
    }
   
  }, [BASE_URL]);




  return (
    <div>
          {invoiceData ? (
  <div style={{ padding: '20px', backgroundColor: '#FFFFFF', maxWidth: '600px', margin: 'auto', fontFamily: 'Courier Prime' }} id='pdf-content'>
      <h2 style={{ textAlign: 'center' }} className='print'>{invoiceData.myDetails?.name || ''}</h2>
      <p style={{ textAlign: 'center' }} className='print'>Ph.No:{invoiceData.myDetails?.phoneNumber|| ''} </p>
      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

      <div style={{ marginTop: '20px', marginLeft: "30px" }}>
        <strong className='print'>To</strong>
      
        <p className='mt-2 print'>{invoiceData.customerDetails?.name || ''}
             </p>


         <p>{invoiceData.customerDetails?.phoneNumber || ''} </p>
      </div>
      

      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

      <h3 style={{ textAlign: 'center', margin: '20px 0' }} className='print'>SALE BILL</h3>

      <p style={{ display: 'flex', justifyContent: "center" }}>
        <span style={{ fontWeight: 'bold' }} className='print'>Invoice No:</span>&nbsp;&nbsp;<span className='print'>{invoiceData.invoiceNumber || ''}</span>
      </p>
      <p style={{ justifyContent: "center", display: 'flex' }} className='print'>
        <span style={{ fontWeight: 'bold' }}>Invoice Date:</span>&nbsp;&nbsp; <span>{ moment(invoiceData.date).format('DD/MM/YYYY') || ''}</span>
      </p>

      {/* <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} /> */}
      <table className='POCtable'>
        <thead>
          <tr className='print'>
            <th style={{border:'1px dashed black'}}>S.No</th>
            <th style={{border:'1px dashed black'}}>Items</th>
            <th style={{border:'1px dashed black'}}>Qty</th>
            <th style={{border:'1px dashed black'}}>Tax</th>
            <th style={{border:'1px dashed black'}}>Dis</th>
            <th style={{border:'1px dashed black'}}>Amt</th>
          </tr>
        </thead>
        <tbody>

          {invoiceData.items?.map((item) => (
            <tr key={item.itemId} className="sale-bill">
              <td  style={{ textAlign: 'center',border:'1px dashed black' }}>{item.SlNo}</td>
              <td  style={{ textAlign: 'center',border:'1px dashed black' }}>{item.itemName}</td>
              <td  style={{ textAlign: 'center',border:'1px dashed black' }}>
                {item.quantity} {item.quantityUnits}
              </td>
              {/* <td  style={{ textAlign: 'center',border:'1px dashed black' }}>{item.price}</td> */}
              <td  style={{ textAlign: 'center',border:'1px dashed black' }}>{item.tax || 0}{item.taxunits}</td>
              <td  style={{ textAlign: 'center',border:'1px dashed black' }}>{item.discount || 0}{item.disunits}</td>
              <td  style={{ textAlign: 'center',border:'1px dashed black' }}>{item.total}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className='poc_gst'>
        <p className='poc_row'>Tax: <span className="value-gap">{invoiceData.tax || 0}{invoiceData.taxunits}</span></p>
        <p className='poc_row'>Discount: <span className="value-gap">{invoiceData.discount || 0}{invoiceData.disunits}</span></p>



    
        {invoiceData.AdditionalCharges?.map((charge) => (
          <p key={charge._id} className="poc_row">{charge.key}<span className="value-gap">{charge.value}</span></p>
        ))}
        {invoiceData.customfields?.map((Custom) => (
          <p key={Custom._id} className="poc_row">{Custom.key}<span className="value-gap">{Custom.value}</span></p>
        ))}
        <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

        <p className='poc_row'><b style={{marginRight:'-35px'}}>Net Amount:</b> <span className="value-gap">{invoiceData.total}</span></p>
        <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

        {invoiceData.payments.map((payment) => (
          <div key={payment._id}>
            <p className="poc_row" ><span style={{marginRight:'-35px'}}>Amount Received: </span><span className="value-gap">{parseFloat(payment.AmountReceived).toFixed(2)}</span></p>

          </div>
        ))}
        <p className="poc_row"><b style={{marginRight:'-10px'}}>Balance:</b> <span className="value-gap">{invoiceData.paymentStatus === 'Unpaid' ? parseFloat(invoiceData.total).toFixed(2) : parseFloat(invoiceData.payments[invoiceData.payments.length - 1]?.BalanceAmount).toFixed(2) || '0.00'}</span></p>
      </div>
      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />
     </div>
  ):(

    <p>No data available</p>
  )}
    </div>
  )
};

export default POC;
